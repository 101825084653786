import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { PageTitle } from '../components/pages';
import '../components/layout.css';

const Page = styled.div`
    background-color: #000;
    padding-bottom: 120px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (min-width: 768px) {
        padding-top: 100px;
    }
`;

const RegOKPageTitle = styled(PageTitle)`
  color: #F5F3F0;
  text-align: center;
`;

const RegOKDesc = styled.div`
  font-family: "Montserrat Medium", sans-serif;
  font-size: 18px;
  text-align: center;
`;

const RegOKButton = styled.a`
  font-family: "Montserrat Medium", sans-serif;
  color: #F5F3F0;
  padding-top: 10px;
  text-transform: uppercase;
  font-size: 14px;
`;

const About = () => (
  <Page id="rejestracja_udana" className="page">
    <RegOKPageTitle>
      Rejestracja opłacona. Dziękujemy
    </RegOKPageTitle>
    <RegOKDesc>
      O wszystkich kolejnych krokach powiadomimy Cię drogą mejlową. Do zobaczenia w Warszawie</RegOKDesc>
    <RegOKButton href="https://bazacentrum.pl/">
      Wróć do strony głównej
    </RegOKButton>
  </Page>
);

export default About;
